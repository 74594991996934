import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

const AllEventLinks = styled.div`
  background-color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;

  h2 {
    padding: 0px 16px;
    color: white;
  }

  ul {
    padding: 0px 16px;
    list-style-type: none;
    line-height: 200%;
  }

  a {
    text-decoration: none;
    color: #52c2f0;
  }
`

const AllVenuesList = () => {
  const venues = useStaticQuery(
    graphql`
      query {
        allAirtable(filter: { table: { eq: "Venues" } } ) {
          edges {
            node {
              id
              data {
                venue_name
                url_slug_venue
              }
            }
          }
        }
      }
    `)
  
  return (
    <AllEventLinks>
    <h2>
      Venues
    </h2>
    <ul>
      {venues.allAirtable.edges.map((venue) => {
        //Just if there is subevents in the next days
        if(venue.node.data.start_datetime_subevent_next_days !== null) 
          return ( 
            <li key={venue.node.id}>
              <Link to={`/${venue.node.data.url_slug_venue}`}>
                {venue.node.data.venue_name}
              </Link>
            </li>
          )
      })}
    </ul>
    </AllEventLinks>
  )
};

export default AllVenuesList;
