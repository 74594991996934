import React, {createRef, useState, useEffect } from "react"
import styled from "styled-components";

const VideoLayout = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
`

const VideoIframeLayout  = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Gap = styled.div`

`

const youtube_parser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
}

const Video = ({ videoSrcURL, videoTitle, ...props }) => {

  const [showVideo, setShowVideo] = useState(false);

  const container = createRef();

  const youtubeId = youtube_parser(videoSrcURL)
 
  useEffect(() => {
    let videoObserver = new IntersectionObserver(onVideoIntersection, {
      rootMargin: '100px 0px',
      threshold: 0.25
    });

    if (window && 'IntersectionObserver' in window) {
        if (container && container.current) {
            videoObserver.observe(container.current);
        }
    } else {
        setShowVideo(true);
    }

    function onVideoIntersection(entries) {
      if (!entries || entries.length <= 0) {
          return;
      }
    
      if (entries[0].isIntersecting) {
          setShowVideo(true);
          videoObserver.disconnect();
      }
    }

  }, [container]);
  
 

  return(
    <Gap>
      <VideoLayout ref={container}>
        { 
          showVideo ? <VideoIframeLayout
            src={`https://www.youtube.com/embed/${youtubeId}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          /> : undefined
        }
      </VideoLayout>
    </Gap>
  )
}

export default Video