import React from "react";
import Img from "gatsby-image"
import styled from "styled-components";

const ImgWrapper = styled.div`
    max-width: 800px;
`

const Gap = styled.div`
  margin-bottom: 30px;
  @media (min-width:801px) {
      margin-bottom: 60px;
  }
`

const GalleryEvent = ({gallery_event}) => {
  return (
    <React.Fragment>
      {gallery_event.localFiles.map((gallery_img) => (
          <Gap>
            <ImgWrapper key={gallery_img.childImageSharp.id}>
                <Img fluid={gallery_img.childImageSharp.fluid} alt="test" />
            </ImgWrapper>
          </Gap>
      ))}
    </React.Fragment>
  );
};

export default GalleryEvent;
