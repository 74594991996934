import React from "react";

//Components
import DateStartEnd from "../components/DateStartEnd";
import Name from "../components/Name";
import Desc from "../components/Desc";
import TeaserImgEvent from "../components/TeaserImgEvent";
import Stars from "../components/Stars";
import Price from "../components/Price";
import GalleryEvent from "../components/GalleryEvent";
import Video from "../components/Video";
import SubEvents from "../components/SubEvents";
import SEO from "../components/seo"

//Categories
import AllVenuesList from "../components/CategoryViews/AllVenuesList";
import TodayTomorrowList from "../components/CategoryViews/TodayTomorrowList";

//Layout
import Card from "../components/Layout/Card";
import Main from "../components/Layout/Main";
import FlexContainer from "../components/Layout/FlexContainer";
import FlexItem from "../components/Layout/FlexItem";

const Events = ({ pageContext }) => {
  return (
    <section>
      <SEO context={pageContext} withSchema={true}/>
      <Main key={pageContext.id}>
        <FlexContainer>
          <FlexItem>
            <Card>
              <DateStartEnd
                start={pageContext.data.start_date_event || "start date TBA"}
                end={pageContext.data.end_date_event || "end date TBA"}
              />
              <Name
                name={
                  pageContext.data.name_event ||
                  "Call the zrce.eu team and blame them that someone really forgot the event name. OMG!"
                }
              />
              <Desc desc={pageContext.data.desc_event || "more infos soon"} />
            </Card>
            {pageContext.data.teaser_img_event ? (
              <TeaserImgEvent
                teaser_img_event={pageContext.data.teaser_img_event}
              />
            ) : (
              <p>no image yet</p>
            )}
            <Card>
              <Stars stars_event={pageContext.data.stars_event || "no rating yet"} />
              <Price stars_event={pageContext.data.price_event || "no price yet"} />
              <p>{pageContext.data.location_event || "-"}</p>
              <p>{pageContext.data.link_event || "-"}</p>
            </Card>
            {pageContext.data.video_url_event ? (
              <Video videoSrcURL={pageContext.data.video_url_event} />
            ) : (
              <p>no video</p>
            )}
            {pageContext.subeventNodes[0] ? (
              <SubEvents subeventNodes={pageContext.subeventNodes} />
            ) : (
              <p>no sub events yet</p>
            )}
          </FlexItem>
          <FlexItem>
            {pageContext.data.gallery_event ? (
            <GalleryEvent gallery_event={pageContext.data.gallery_event} />
            ) : (
              <p>no gallery</p>
            )}
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <TodayTomorrowList />
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <AllVenuesList />
          </FlexItem>
        </FlexContainer>
      </Main>
    </section>
  );
};

export default Events;
