import React from "react";
import moment from 'moment'

const DateStartEnd = ({start, end}) => {
  return (
    <p>{moment(start).format("DD.MM.YYYY")} - {moment(end).format("DD.MM.YYYY")}</p>
  );
};

export default DateStartEnd;
